import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { Id } from "@/convex/_generated/dataModel";
import { api } from "@/convex/_generated/api";
import { useQuery } from "convex/react";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function useStorageUrl(storageId: Id<"_storage"> | undefined) {
  return useQuery(api.storage.getUrl, storageId ? { storageId } : "skip");
}

export function calculateFees(price: number) {
  const fee_percentage = 20;
  const platformFee = price * (fee_percentage / 100);
  const sellerAmount = price - platformFee;
  
  return {
    total: price,
    platformFee,
    sellerAmount,
  };
}

export function formatPrice(price: number): string {
  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  }).format(price);
}

export const formatLastUpdated = (value: string) => {
  const mapping = {
    'this_week': 'This Week',
    '1_month': 'Last Month',
    '3_months': 'Last 3 Months',
    '6_months': 'Last 6 Months',
    '1_year': 'Last Year',
    'all_time': 'All Time',
    'daily_updates': 'Updated Daily',
    'weekly_updates': 'Updated Weekly',
    'monthly_updates': 'Updated Monthly'
  };

  return mapping[value as keyof typeof mapping] || value;
};
