import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["ConvexClientProvider"] */ "/vercel/path0/components/ConvexClientProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LoadingProvider"] */ "/vercel/path0/components/LoadingProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/SyncUserWithConvex.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ClientClerkProvider"] */ "/vercel/path0/node_modules/.pnpm/@clerk+nextjs@6.12.2_next@15.0.3_@babel+core@7.26.9_react-dom@19.0.0-rc-66855b96-202411_d29910a1373a7ad6e3f1150ebc0e9e02/node_modules/@clerk/nextjs/dist/esm/app-router/client/ClerkProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@clerk+nextjs@6.12.2_next@15.0.3_@babel+core@7.26.9_react-dom@19.0.0-rc-66855b96-202411_d29910a1373a7ad6e3f1150ebc0e9e02/node_modules/@clerk/nextjs/dist/esm/app-router/client/keyless-cookie-sync.js");
;
import(/* webpackMode: "eager", webpackExports: ["AuthenticateWithRedirectCallback","ClerkLoaded","ClerkLoading","RedirectToCreateOrganization","RedirectToOrganizationProfile","RedirectToSignIn","RedirectToSignUp","RedirectToUserProfile"] */ "/vercel/path0/node_modules/.pnpm/@clerk+nextjs@6.12.2_next@15.0.3_@babel+core@7.26.9_react-dom@19.0.0-rc-66855b96-202411_d29910a1373a7ad6e3f1150ebc0e9e02/node_modules/@clerk/nextjs/dist/esm/client-boundary/controlComponents.js");
;
import(/* webpackMode: "eager", webpackExports: ["useAuth","useClerk","useEmailLink","useOrganization","useOrganizationList","useReverification","useSession","useSessionList","useSignIn","useSignUp","useUser"] */ "/vercel/path0/node_modules/.pnpm/@clerk+nextjs@6.12.2_next@15.0.3_@babel+core@7.26.9_react-dom@19.0.0-rc-66855b96-202411_d29910a1373a7ad6e3f1150ebc0e9e02/node_modules/@clerk/nextjs/dist/esm/client-boundary/hooks.js");
;
import(/* webpackMode: "eager", webpackExports: ["PromisifiedAuthProvider"] */ "/vercel/path0/node_modules/.pnpm/@clerk+nextjs@6.12.2_next@15.0.3_@babel+core@7.26.9_react-dom@19.0.0-rc-66855b96-202411_d29910a1373a7ad6e3f1150ebc0e9e02/node_modules/@clerk/nextjs/dist/esm/client-boundary/PromisifiedAuthProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["CreateOrganization","GoogleOneTap","OrganizationList","OrganizationProfile","OrganizationSwitcher","SignIn","SignInButton","SignInWithMetamaskButton","SignOutButton","SignUp","SignUpButton","UserButton","UserProfile","Waitlist"] */ "/vercel/path0/node_modules/.pnpm/@clerk+nextjs@6.12.2_next@15.0.3_@babel+core@7.26.9_react-dom@19.0.0-rc-66855b96-202411_d29910a1373a7ad6e3f1150ebc0e9e02/node_modules/@clerk/nextjs/dist/esm/client-boundary/uiComponents.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.3_@babel+core@7.26.9_react-dom@19.0.0-rc-66855b96-20241106_react@19.0.0-rc-66_97b13fefeb6929ee4a150091baae5fe3/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.3_@babel+core@7.26.9_react-dom@19.0.0-rc-66855b96-20241106_react@19.0.0-rc-66_97b13fefeb6929ee4a150091baae5fe3/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.3_@babel+core@7.26.9_react-dom@19.0.0-rc-66855b96-20241106_react@19.0.0-rc-66_97b13fefeb6929ee4a150091baae5fe3/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\",\"600\",\"700\",\"800\"],\"display\":\"swap\",\"variable\":\"--font-poppins\"}],\"variableName\":\"poppins\"}");
