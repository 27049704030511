// Simple environment helper
export const getEnvConfig = () => {
  // Check if we're in a production environment
  const isProduction = process.env.NODE_ENV === 'production';
  const isLiveMode = process.env.STRIPE_LIVE_MODE === 'true' || process.env.STRIPE_SECRET_KEY?.startsWith('sk_live_');
  
  console.log("Environment config:", {
    nodeEnv: process.env.NODE_ENV,
    isProduction,
    isLiveMode,
    stripeKeyType: process.env.STRIPE_SECRET_KEY?.startsWith('sk_live_') ? 'LIVE' : 'TEST'
  });
  
  // For development, use the values from .env
  // For production, use the hardcoded values or environment variables
  return {
    // App
    appUrl: process.env.NEXT_PUBLIC_APP_URL || (isProduction ? 'https://getlists.io' : 'http://localhost:3000'),
    isProduction,
    isLiveMode,
    
    // Auth - use the correct keys based on environment
    clerkDomain: isProduction 
      ? process.env.NEXT_PUBLIC_CLERK_DOMAIN || 'clerk.getlists.io'
      : process.env.NEXT_PUBLIC_CLERK_DOMAIN || 'fresh-monster-41.clerk.accounts.dev',
    
    clerkPublishableKey: isProduction
      ? process.env.NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY || 'pk_live_Y2xlcmsuZ2V0bGlzdHMuaW8k'
      : process.env.NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY || 'pk_test_ZnVsbC1hc3AtNTMuY2xlcmsuYWNjb3VudHMuZGV2JA',
    
    // API
    convexUrl: process.env.NEXT_PUBLIC_CONVEX_URL || '',
    
    // Payments
    stripePublishableKey: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY || '',
    stripeLiveMode: isLiveMode,
    
    // Special case for localhost in production mode
    isLocalhost: typeof window !== 'undefined' && 
      (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1')
  };
}; 