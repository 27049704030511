"use client";

import { SignInButton, SignedIn, SignedOut, UserButton } from "@clerk/nextjs";
import { usePathname } from "next/navigation";
import Logo from "./Logo";
import Link from "next/link";

function Header() {
  const pathname = usePathname();

  return (
    <div className="border-b bg-white sm:pt-0 pt-4">
      <div className="max-w-7xl mx-auto px-4">
        {/* Reduced mobile padding (p-2 on mobile, p-6 on large devices) */}
        <div className="flex flex-col lg:flex-row items-center gap-4 p-2 lg:p-6">
          <div className="flex items-center justify-between w-full lg:w-auto">
            {/* Increased logo size */}
            <div className="w-[160px] sm:w-[400px] lg:w-[220px]">
              <Logo />
            </div>

            <div className="lg:hidden scale-125">
              <SignedIn>
                <UserButton afterSignOutUrl="/" />
              </SignedIn>
              <SignedOut>
                <SignInButton mode="modal">
                  <button className="bg-gray-50 text-gray-700 px-4 sm:pr-6 py-2 text-sm rounded-full hover:bg-gray-100 transition-colors duration-200 border border-gray-200">
                    Start Selling / Buying
                  </button>
                </SignInButton>
              </SignedOut>
            </div>
          </div>

          <div className="hidden lg:block ml-auto">
            <SignedIn>
              <div className="flex items-center gap-6">
                <Link href="/seller">
                  <button
                    className={`px-7 py-3 text-base font-medium rounded-full transition-all duration-200 shadow-sm
                    ${
                      pathname?.startsWith("/seller")
                        ? "bg-blue-600 text-white hover:bg-blue-700"
                        : "bg-gray-50 text-gray-700 hover:bg-gray-100 border border-gray-200"
                    }`}
                  >
                    Sell Leads
                  </button>
                </Link>

                <Link href="/tickets">
                  <button
                    className={`px-7 py-3 text-base font-medium rounded-full transition-all duration-200 shadow-sm
                    ${
                      pathname?.startsWith("/tickets")
                        ? "bg-blue-600 text-white hover:bg-blue-700"
                        : "bg-gray-50 text-gray-700 hover:bg-gray-100 border border-gray-200"
                    }`}
                  >
                    Bought Leads
                  </button>
                </Link>
                <div className="scale-125">
                  <UserButton afterSignOutUrl="/" />
                </div>
              </div>
            </SignedIn>
            <SignedOut>
              <SignInButton mode="modal">
                <button className="bg-gray-50 text-gray-700 px-7 py-3 text-base rounded-full hover:bg-gray-100 transition-colors duration-200 border border-gray-200">
                  Start Selling or Buying
                </button>
              </SignInButton>
            </SignedOut>
          </div>

          {/* Mobile Action Buttons with reduced bottom padding */}
          <div className="lg:hidden w-full flex justify-center gap-4 pb-1">
            <SignedIn>
              <Link href="/seller" className="flex-1">
                <button
                  className={`w-full px-5 py-3 text-base font-medium rounded-full transition-all duration-200 shadow-sm
                  ${
                    pathname?.startsWith("/seller")
                      ? "bg-blue-600 text-white hover:bg-blue-700"
                      : "bg-gray-50 text-gray-700 hover:bg-gray-100 border border-gray-200"
                  }`}
                >
                  Seller
                </button>
              </Link>

              <Link href="/tickets" className="flex-1">
                <button
                  className={`w-full px-5 py-3 text-base font-medium rounded-full transition-all duration-200 shadow-sm
                  ${
                    pathname?.startsWith("/tickets")
                      ? "bg-blue-600 text-white hover:bg-blue-700"
                      : "bg-gray-50 text-gray-700 hover:bg-gray-100 border border-gray-200"
                  }`}
                >
                  Buyer
                </button>
              </Link>
            </SignedIn>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
