"use client";

import Image from "next/image";
import Link from "next/link";

export default function Logo() {
  return (
    <Link href="/" className="flex items-center">
      <div className="relative w-32 h-12">
        <Image
          src="/logo.svg"
          alt="GetLists Logo"
          fill
          priority
          className="object-contain"
        />
      </div>
    </Link>
  );
} 